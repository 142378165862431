import { useState, useEffect } from "react";
import { Button, Input, Textarea } from "react-daisyui";
import { getStates, getDivisions, getDivisionHierarchy } from "../services/LocationsService";
import ImageComponent from "../components/ImageComponent";
import { createAgent, getAgentData, updateAgent } from "../services/AgentsService";
import OverlayComponent from "../components/OverlayComponent";
import NavbarComponent from "../components/NavbarComponent";
import SelectDropdownComponent from "../components/SelectDropdownComponent";
import { useParams } from "react-router-dom";
import NotificationComponent from "../components/NotificationComponent";
import { getSlugFromName } from "../services/LocationsService";

export default function AgentsPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [addPage, setAddPage] = useState(true);
  const [notification, setNotification] = useState({ show: false, message: '', type: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const params = useParams();

  // Agent data states
  const [agentId, setAgentId] = useState('');
  const [agentName, setAgentName] = useState('');
  const [agentPhone, setAgentPhone] = useState('');
  const [divisionFilter, setDivisionFilter] = useState([]); // [id, id, id]
  const [divisionOptions, setDivisionOptions] = useState({}); // {states: [{id, name, slug}], districts: [{id, name, slug, parent_id}], blocks: [{id, name, slug, parent_id}]}
  const [divisionHierarchy, setDivisionHierarchy] = useState([]); // [district, mandal, village]
  const [estdLandsCount, setEstdLandsCount] = useState(''); // 100
  const [estdPlotsCount, setEstdPlotsCount] = useState(''); // 200
  const [googleLocationLink, setGoogleLocationLink] = useState('');
  const [latitude, setLatitude] = useState(''); // 18.509600
  const [longitude, setLongitude] = useState(''); // 79.635000
  const [description, setDescription] = useState('');
  const [landsPriceRangeMin, setLandsPriceRangeMin] = useState('');
  const [landsPriceRangeMax, setLandsPriceRangeMax] = useState('');
  const [plotsPriceRangeMin, setPlotsPriceRangeMin] = useState('');
  const [plotsPriceRangeMax, setPlotsPriceRangeMax] = useState('');
  const [profilePicture, setProfilePicture] = useState({
    imageFiles: [],
    imageLinks: [],
    removedImageLinks: []
  });

  useEffect(() => {
    (async () => {
      await fetchStates();
      if (params.agent_id) {
        setAddPage(false);
        await fetchAgentData(params.agent_id);
      } else {
        setAddPage(true);
      }
    })();
  }, [params]);

  const triggerNotification = (message, type) => {
    setNotification({ show: true, message: message, type: type });
    setTimeout(() => {
      setNotification({ show: false, message: '', type: '' });
    }, 3000);
  };

  const fetchStates = async () => {
    const data = await getStates();
    const options = data.results.map((stateObj) => ({ 'id': stateObj.id, 'name': stateObj.name, 'slug': stateObj.slug }));
    setDivisionOptions({ 'states': options });
  }

  const fetchDivisionHierarchy = async (stateSlug) => {
    const data = await getDivisionHierarchy({ 'state_slug': stateSlug });
    setDivisionHierarchy(data);
    return data;
  }

  const fetchDivisionOptions = async (parentDivisionId, divisionType) => {
    const data = await getDivisions({
      'parent_id': parentDivisionId,
      'division_type': divisionType
    });

    setDivisionOptions(prev => ({
      ...prev,
      [divisionType]: data.results || [],
    }));
  }

  const fetchAgentData = async (agentId) => {
    setIsLoading(true);
    try {
      const data = await getAgentData(agentId);

      setAgentId(data.id);
      setAgentName(data.account.name);
      setAgentPhone(data.account.phone_number);
      setEstdLandsCount(data.estd_lands_count);
      setEstdPlotsCount(data.estd_plots_count);
      if (data.location.lat) {
        setLatitude(data.location.lat);
      }
      if (data.location.lng) {
        setLongitude(data.location.lng);
      }
      setDescription(data.description);
      setLandsPriceRangeMin(data.lands_price_range_per_unit.min);
      setLandsPriceRangeMax(data.lands_price_range_per_unit.max);
      setPlotsPriceRangeMin(data.plots_price_range_per_unit.min);
      setPlotsPriceRangeMax(data.plots_price_range_per_unit.max);

      // Handle profile picture
      const profilePic = data.agent_images.find(img => img.category === "profile picture");
      if (profilePic) {
        setProfilePicture({
          imageFiles: [],
          imageLinks: [profilePic.src],
          removedImageLinks: []
        });
      }

      const stateSlug = getSlugFromName(data.division_info[0].name);
      const hierarchy = await fetchDivisionHierarchy(stateSlug);
      hierarchy.forEach((division, index) => {
        // get the division options for the division
        const divisionId = data.division_info[index].id;
        (async () => {
          await fetchDivisionOptions(divisionId, division);
        })();
      });

      setDivisionFilter(
        data.division_info.map((division) => {
          return division.id;
        })
      );

    } catch (error) {
      console.log(error);
      triggerNotification(`Unable to fetch agent with id: ${agentId}`, 'error');
    }
    setIsLoading(false);
  };

  const divisionOnChangeHandler = async (division, index, newValue) => {
    const castedNewValue = parseInt(newValue);
    if (castedNewValue === 0) {
      setDivisionHierarchy([]);
      setDivisionFilter([]);
      setDivisionOptions({ 'states': divisionOptions.states });
    } else {
      setDivisionFilter((prev) => {
        const newFilter = [...prev.slice(0, index), castedNewValue];
        return newFilter;
      });

      if (index === 0) {
        const stateSlug = divisionOptions.states.find(state => state.id === castedNewValue)?.slug;
        if (stateSlug) {
          const newHierarchy = await fetchDivisionHierarchy(stateSlug);
          if (newHierarchy.length > 0) {
            fetchDivisionOptions(castedNewValue, newHierarchy[0]);
          }
        }
      } else {
        const nextDivision = divisionHierarchy[index];
        if (nextDivision) {
          await fetchDivisionOptions(castedNewValue, nextDivision);
        }
      }
    }
  };

  const getUpdatedAgentData = () => {
    const agentParams = {
      account: { name: agentName, phone_number: agentPhone, profile_picture: { file_data: profilePicture.imageFiles[0].src, file_name: profilePicture.imageFiles[0].name } },
      agent_division: divisionFilter,
      location: { lat: latitude, lng: longitude },
      description: description,
      lands_price_range_per_unit: { min: parseFloat(landsPriceRangeMin), max: parseFloat(landsPriceRangeMax) },
      plots_price_range_per_unit: { min: parseFloat(plotsPriceRangeMin), max: parseFloat(plotsPriceRangeMax) },
      estd_lands_count: parseInt(estdLandsCount),
      estd_plots_count: parseInt(estdPlotsCount),
    };

    agentParams.agent_media = [];
    agentParams.removed_media_files = [];

    if (profilePicture.imageFiles.length > 0) {
      agentParams.agent_media.push({
        category: "profile picture",
        src: { file_data: profilePicture.imageFiles[0].src, file_name: profilePicture.imageFiles[0].name }
      });
    }

    if (profilePicture.removedImageLinks.length > 0) {
      agentParams.removed_media_files = profilePicture.removedImageLinks;
    }

    return agentParams;
  };



  const getCoordinatesFromGoogleLinkHandler = () => {
    const regex = /@(-?\d+\.\d+),(-?\d+\.\d+)/; // Regular expression to match lat, lng
    const match = googleLocationLink.match(regex);
    if (match) {
      const lat = match[1]; // Latitude
      const lng = match[2]; // Longitude
      setLatitude(lat);
      setLongitude(lng);
    } else {
      triggerNotification('Invalid Google Maps link. Please check the format.', 'error');
    }
  };

  const agentSubmitHandler = async () => {
    if (!agentName || !agentPhone || divisionFilter.length === 0 || !estdLandsCount || !estdPlotsCount ||
      (profilePicture.imageFiles.length === 0 && profilePicture.imageLinks.length === 0)) {
      setErrorMessage('Mandatory fields are missing.');
      return;
    }

    setErrorMessage('');
    const agentParams = getUpdatedAgentData();
    setIsLoading(true);
    try {
      const data = addPage ? await createAgent(agentParams) : await updateAgent(agentId, agentParams);
      if (addPage) {
        window.history.replaceState(null, "", `/agents/${data.id}`);
        setAgentId(data.id);
        setAddPage(false);
        triggerNotification(`Agent created with id: ${data.id}`, 'success');
      } else {
        triggerNotification('Agent updated successfully', 'success');
      }
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrorMessage('Mandatory fields are missing');
      } else if (error.response && error.response.status === 401) {
        setErrorMessage('You are not authorized. Please login.');
      } else {
        setErrorMessage('Unknown error. Please contact support.');
      }
      triggerNotification('Operation failed.', 'error');
    }
    setIsLoading(false);
  };

  return (
    <div>
      {isLoading && <OverlayComponent />}
      <NavbarComponent />
      <div className="m-5">
        <div className="w-4/5 m-auto">
          <div className="flex w-full items-center justify-center relative">
            <label className="label text-2xl text-bold text-center">
              {addPage ? 'Add New Agent' : 'Update Agent'}
            </label>
          </div>

          <div className="flex w-full p-4 mt-4 component-preview gap-4 font-sans border-2 border-solid rounded-xl border-[#ffde59]">
            <div className="form-control w-full max-w-xs">
              <label className="label">
                <span className="label-text">Name<span className="text-red-600">*</span></span>
              </label>
              <Input
                type="text"
                value={agentName}
                onChange={(e) => setAgentName(e.target.value)}
              />
            </div>
            <div className="form-control w-full max-w-xs">
              <label className="label">
                <span className="label-text">Phone Number<span className="text-red-600">*</span></span>
              </label>
              <Input
                type="text"
                value={agentPhone}
                onChange={(e) => setAgentPhone(e.target.value)}
              />
            </div>
          </div>

          <div className="flex w-full p-4 mt-4 component-preview gap-4 font-sans border-2 border-solid rounded-xl border-[#ffde59]">
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">Profile Picture<span className="text-red-600">*</span></span>
              </label>
              <ImageComponent
                multipleImages={false}
                onChangeHandler={setProfilePicture}
                defaultValues={profilePicture}
              />
            </div>
          </div>

          <div className="flex w-full p-4 mt-4 component-preview gap-4 font-sans border-2 border-solid rounded-xl border-[#ffde59]">
            <div className="form-control w-full max-w-xs">
              <label className="label">
                <span className="label-text">States<span className="text-red-600">*</span></span>
              </label>
              <SelectDropdownComponent
                labelName='All States'
                options={divisionOptions['states'] || []}
                currValue={divisionFilter[0] || 0}
                onChangeHandler={(value) => divisionOnChangeHandler('state', 0, value)}
              />
            </div>
            {divisionHierarchy.map((division, index) => (
              <div className="form-control w-full max-w-xs" key={division}>
                <label className="label">
                  <span className="label-text">{division}<span className="text-red-600">*</span></span>
                </label>
                <SelectDropdownComponent
                  labelName={`All ${division}`}
                  options={divisionOptions[division] || []}
                  currValue={divisionFilter[index + 1] || 0}
                  onChangeHandler={(value) => divisionOnChangeHandler(division, index + 1, value)}
                />
              </div>
            ))}
          </div>

          <div className="flex w-full p-4 mt-4 component-preview gap-4 font-sans border-2 border-solid rounded-xl border-[#ffde59]">
            <div className="form-control w-full max-w-xs">
              <label className="label">
                <span className="label-text">Estimated Lands Count<span className="text-red-600">*</span></span>
              </label>
              <Input
                type="number"
                value={estdLandsCount}
                onChange={(e) => setEstdLandsCount(e.target.value)}
              />
            </div>
            <div className="form-control w-full max-w-xs">
              <label className="label">
                <span className="label-text">Estimated Plots Count<span className="text-red-600">*</span></span>
              </label>
              <Input
                type="number"
                value={estdPlotsCount}
                onChange={(e) => setEstdPlotsCount(e.target.value)}
              />
            </div>
          </div>

          <div className="flex w-full p-4 mt-4 component-preview gap-4 font-sans border-2 border-solid rounded-xl border-[#ffde59]">
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">Google Location Link</span>
              </label>
              <div className="flex gap-2">
                <Input
                  type="text"
                  value={googleLocationLink}
                  onChange={(e) => setGoogleLocationLink(e.target.value)}
                />
                <Button color="primary" className="" onClick={getCoordinatesFromGoogleLinkHandler}>
                  Get Coordinates
                </Button>
              </div>
            </div>
            <div className="form-control w-full max-w-xs">
              <label className="label">
                <span className="label-text">Latitude</span>
              </label>
              <Input
                type="text"
                value={latitude}
                onChange={(e) => setLatitude(e.target.value)}
              />
            </div>
            <div className="form-control w-full max-w-xs">
              <label className="label">
                <span className="label-text">Longitude</span>
              </label>
              <Input
                type="text"
                value={longitude}
                onChange={(e) => setLongitude(e.target.value)}
              />
            </div>
          </div>

          <div className="flex w-full p-4 mt-4 component-preview gap-4 font-sans border-2 border-solid rounded-xl border-[#ffde59]">
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text">Description</span>
              </label>
              <Textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>

          <div className="flex w-full p-4 mt-4 component-preview gap-4 font-sans border-2 border-solid rounded-xl border-[#ffde59]">
            <div className="form-control w-full max-w-xs">
              <label className="label">
                <span className="label-text">Lands Price Range (Min)</span>
              </label>
              <Input
                type="number"
                value={landsPriceRangeMin}
                onChange={(e) => setLandsPriceRangeMin(e.target.value)}
              />
            </div>
            <div className="form-control w-full max-w-xs">
              <label className="label">
                <span className="label-text">Lands Price Range (Max)</span>
              </label>
              <Input
                type="number"
                value={landsPriceRangeMax}
                onChange={(e) => setLandsPriceRangeMax(e.target.value)}
              />
            </div>
          </div>

          <div className="flex w-full p-4 mt-4 component-preview gap-4 font-sans border-2 border-solid rounded-xl border-[#ffde59]">
            <div className="form-control w-full max-w-xs">
              <label className="label">
                <span className="label-text">Plots Price Range (Min)</span>
              </label>
              <Input
                type="number"
                value={plotsPriceRangeMin}
                onChange={(e) => setPlotsPriceRangeMin(e.target.value)}
              />
            </div>
            <div className="form-control w-full max-w-xs">
              <label className="label">
                <span className="label-text">Plots Price Range (Max)</span>
              </label>
              <Input
                type="number"
                value={plotsPriceRangeMax}
                onChange={(e) => setPlotsPriceRangeMax(e.target.value)}
              />
            </div>
          </div>

          <div className="flex w-full items-center justify-center pt-10 pb-2">
            <Button color="primary" className="w-2/5" onClick={agentSubmitHandler}>
              {addPage ? 'Add Agent' : 'Update Agent'}
            </Button>
          </div>

          {errorMessage && (
            <div className="flex w-full items-center justify-center">
              <label className="label text-red-500 text-sm">{errorMessage}</label>
            </div>
          )}
        </div>
      </div>
      {notification.show && <NotificationComponent message={notification.message} type={notification.type} />}
    </div>
  );
}
