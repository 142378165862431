import { useState, useEffect } from "react";
import { Button, Input, Table } from "react-daisyui";
import { getAgents } from "../services/AgentsService";
import NavbarComponent from "../components/NavbarComponent";
import OverlayComponent from "../components/OverlayComponent";
import { isPhoneNumber } from "../utils/validators";
import { useNavigate } from "react-router-dom";

export default function AgentsDashboardPage() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [agents, setAgents] = useState([]);
  const [phoneFilter, setPhoneFilter] = useState('');
  const [phoneFilterValid, setPhoneFilterValid] = useState(false);
  const [resultsCount, setResultsCount] = useState(0);

  useEffect(() => {
    fetchAgents();
  }, []);

  useEffect(() => {
    setPhoneFilterValid(isPhoneNumber(phoneFilter));
  }, [phoneFilter]);

  const fetchAgents = async (params = {}) => {
    setIsLoading(true);
    try {
      const data = await getAgents(params);
      setAgents(data.results);
      setResultsCount(data.count);
    } catch (error) {
      console.error("Error fetching agents:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePhoneChange = (e) => {
    setPhoneFilter(e.target.value);
  };

  const handleSearch = () => {
    if (phoneFilterValid) {
      fetchAgents({ 'phone-number': phoneFilter });
    }
  };

  const getDivisionNames = (divisions) => {
    if (divisions.length === 0) return '';
    return divisions.map(div => div.name).join(', ');
  };

  return (
    <div>
      {isLoading && <OverlayComponent />}
      <NavbarComponent />
      <div className="m-5">
        <div className="w-4/5 m-auto">
          <div className="flex p-4 mt-4 component-preview items-center justify-between gap-2 font-sans">
            <div className="flex gap-2 items-center">
              <label className="label">
                <span className="label-text">Agent Phone Number</span>
              </label>
              <Input type="text" maxLength={10} value={phoneFilter} onChange={handlePhoneChange} />
              <Button onClick={handleSearch} disabled={!phoneFilterValid}>Search</Button>
            </div>
          </div>

          <div className='m-3'>
            {resultsCount > 0 && (<label className="label py-3 text-sm">Showing {resultsCount} agents</label>)}
            <Table className="border-2 border-solid border-[#ffde59]">
              <Table.Head>
                <span>ID</span>
                <span>Name</span>
                <span>Divisions</span>
                <span>Estimated Lands Count</span>
                <span>Estimated Plots Count</span>
              </Table.Head>

              <Table.Body>
                {agents.map((agent) => (
                  <Table.Row key={`agent_${agent.id}`} onClick={() => navigate(`/agents/${agent.id}`)}>
                    <span>{agent.id}</span>
                    <span>{agent.account.name}</span>
                    <span>{getDivisionNames(agent.division_info)}</span>
                    <span>{agent.estd_lands_count}</span>
                    <span>{agent.estd_plots_count}</span>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}
